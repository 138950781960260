import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import axios from "axios";
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
// import '../node_modules/bootstrap/dist/css/bootstrap.css';
import './Assets/css/loadingAnimation.css';
import { CartProvider } from 'react-use-cart';
import Home from './Pages/Home.js'
import Products from './Pages/Products';
import ProductView from './Pages/ProductView';
import Cart from './Pages/Cart';
import Faq from './Pages/Faq';
import ContactUs from './Pages/ContactUs';
import Checkout from './Pages/Checkout';
import CategoryWiseProducts from './Pages/CategoryWiseProducts';
import Login from './Pages/Login';
import Registration from './Pages/Registration';
import CustomerDashboard from './Pages/CustomerDashboard';

axios.defaults.baseURL="https://ecommerceapi.fatmonk.studio/api/";
// axios.defaults.baseURL="http://127.0.0.1:8000/api/";
axios.defaults.headers.common["Accept"] = 'application/json';

var token = null;
if (localStorage.getItem("user")) {
  var obj = JSON.parse(localStorage.getItem("user"));
  token = obj.token;
}
axios.defaults.headers.common["Authorization"] = token;

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <CartProvider>
  <React.StrictMode>
    <Router>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/products" element={<Products />} />
        <Route exact path="/product/:id/:name" element={<ProductView />} />
        <Route exact path="/cart" element={<Cart />} />
        <Route exact path="/faq" element={<Faq />} />
        <Route exact path="/contact" element={<ContactUs />} />
        <Route exact path="/checkout" element={<Checkout />} />
        <Route exact path="/product-categories/:id/:cname" element={<CategoryWiseProducts />} />
        <Route exact path="/login" element={<Login/>} />
        <Route exact path="/register" element={<Registration />} />
        {/* <Route exact path="/customer/dashboard" element={<CustomerDashboard />} /> */}
        <Route exact path="/customer/:pgType" element={<CustomerDashboard />} />
        {/* <Route  exact path="product/:productId"    element={<ProductView />} /> 
        <Route exact path="about" element={<Home />} />
        <Route exact path="/cart" element={<CartView />} /> */}

      </Routes>
    </Router>
  </React.StrictMode>
  </CartProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
